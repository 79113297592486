const maximumScaling = 6;
const expandClass = 'is-expand';

/**
 * Показ всех строчек таблицы
 * @param $rows - строчки для показа
 * @param button - кнопка-тогл
 */
function showRows($rows, button) {
	//показыаем все строки
	$rows.show();
	//добавляем класс раскрытия
	button.addClass(expandClass);
	//меняем текст на тогле
	if (getLang() == 'ru') {
		button.html('Свернуть');
	} else if (getLang() == 'en') {
		button.html('Collapse table');
	}
}
/**
 * Скрытие строчек в таблице
 * @param $rows - строки таблицы
 * @param visibleRows - количество строчек, которые нужно оставить показанным
 * @param button - кнопка-тогл
 */
function hideRows($rows, visibleRows, button, user = true) {
	//скрываем все строки, кроме минимального количества видимых
	$rows
		.not($rows.slice(0, visibleRows))
		.hide();
	//удаляем классы раскрытия
	button.removeClass(expandClass);
	//меняем текст на тогле
	if (getLang() == 'ru') {
		button.html('Показать всю таблицу');
	} else if (getLang() == 'en') {
		button.html('Show the entire table');
	}
	//скролим к таблице
	if (user) {
		$(window).scrollTop(button.closest('.table').offset().top);
	}
}
/**
 * Переключение состоянии таблицы - показывать переданные строки или скрывать
 * @param $rows - строки
 * @param visibleRows - количество, которое нужно оставлять показанным
 * @param button - кнопка-тогл
 */
function toggleVisibleRows($rows, visibleRows, button) {
	//Если у обработчика есть класс раскрытия, т.е. блок нужно сворачивать
	if (button.hasClass(expandClass)) {
		hideRows($rows, visibleRows, button);
	} else {
		showRows($rows, button);
	}
	button.blur();
}
/**
 * Метод добавляет кнопку показа-скрытия дополнительных строк в таблице
 * @param $table - контейнер таблицы
 * @param visibleRows - количество строчек, который нужно оставлять раскрытыми
 */
function addToggleVisibleRowsButton($table, visibleRows) {
	// debugger;
	let $toggle = $table.find('.js-toggle-visible-rows'); //Пытаемся найти элемент с созданной ранее кнопкой
	let $rows = $table.find('tbody>tr'); //Получаем все строчки в tbody
	//Количество найденных строк больше, чем минимально показываемое количество?
	if ($rows.length > visibleRows) {
		//Была ли добавлена ранее кнопка?
		if ($toggle.length) {
			return false;
		} else {
			//Создаем элемент кнопки
			let button = $('<button class="btn btn--bordered table__toggle-visible-rows-trigger js-toggle-visible-rows"/>');
			//Прописываем название кнопки
			if (getLang() == 'ru') {
				button.html('Показать всю таблицу');
			} else if (getLang() == 'en') {
				button.html('Show the entire table');
			}
			//Добавляем кнопку в элеменет таблицы
			$table
				.addClass('table--collapsed')
				.append(button);
			$table
				.find('table')
				.wrap("<div class='table__container'></div>");
			//Создаем обработчик события клик
			button.on('click', () => toggleVisibleRows($rows, visibleRows, button));
			//скрываем строки посли подгрузки кнопки
			hideRows($rows, visibleRows, button, false);
		}
	}
}
$(document).ready(function () {
	$('.table').each(function (index, el) {
		const $this = $(this);
		//Параметр количества показываемых по умолчанию строчек
		let visibleRowsCount = $this.attr('data-visible-rows');
		if (typeof visibleRowsCount != 'undefined') {
			addToggleVisibleRowsButton($this, visibleRowsCount);
		}
	});
});
