//Очистка формы
global.resetForm = function ($form = $('.js-form')) {
	$form.each(function (key, form)	{
		let $form = $(form);
		var validator = $form.validate();
		validator.resetForm();
		$form.trigger('reset');

		$form.find('input[type=text], input[type=email], input[type=password], input[type=file], select, textarea')
			.val('')
			.removeClass('form__label--success form__label--error')
			.closest('label')
			.removeClass('form__label--focused');

		$form.find('select').each((i, el) => {
			$(el)
				.val($(el).find('option').eq(0).val())
				.trigger('change');
		})

		$form.find('input[type=radio], input[type=checkbox]')
			.prop('checked', false)
			.removeAttr('checked')
			.removeAttr('selected')
			.closest('label')
			.removeClass('form__label--success form__label--error checkbox__label--checked radio__label--checked')
			.closest('.checkbox, .radio')
			.removeClass('form__label--success form__label--error');

		$form.find('#reloadCaptcha').trigger('click')
	});
};

//Валидация формы
global.initValidationForms = function (form = '.js-form') {
	$(form).each(function (key, form)	{
		$(form).validate({
			errorElement: 'em',
			errorClass: 'form__label--error',
			validClass: '',
			submitHandler: function (form, event) {
				if (!$(form).hasClass('js-form--non-ajax')) {
					event.preventDefault();
					let $captcha = $(form).find('input[name="captcha_sid"]'),
						$submitBtn = $(form).find('input[type=submit]'),
						$preloader = $(`<div class="form__preloader">
															<img class="form__preloader-img" src="/images/svg/loader.svg">
														</div>`);

					function startSend() {
						$submitBtn
							.addClass('btn--disabled')
							.prop('disabled', true);

						$preloader
							.appendTo($(form))
							.velocity('fadeIn');
					}

					function endSend() {
						if ($submitBtn.hasClass('btn--disabled') || $(form).find('.form__preloader').length) {
							$submitBtn
								.removeClass('btn--disabled')
								.prop('disabled', false);
							$(form).find('.form__preloader')
								.velocity('fadeOut')
								.remove();
						}
					}

					startSend();

					if ($captcha.length) {
						$.post('/ajax/captcha.php', $(form).serialize(), function (response) {
							if (response.success) {
								$.post('/ajax/forms.php', $(form).serialize(), function (response) {
									if (response.success) {
										showNotyfications(response.msg, {type: 'success'});
										resetForm($(form));
										endSend();
									} else {
										showNotyfications(response.msg, {type: 'error'});
										endSend();
									}
								}, 'json');
							} else {
								showNotyfications(response.msg, {type: 'error'});
								endSend();
							}

							/* refresh captcha */
							$(form).find('input[name="captcha_sid"]').val(response.captcha);
							Recaptchafree.reset();
						}, 'json');
					} else {
						$.post('/ajax/forms.php', $(form).serialize(), function (response) {
							if (response.success) {
								showNotyfications(response.msg, {type: 'success'});
								resetForm($(form));
								endSend();
							} else {
								showNotyfications(response.msg, {type: 'error'});
								endSend();
							}
						}, 'json');
					}

				} else {
					if ($(form).valid()) {
						form.submit();
					}
				}
				return false; // prevent normal form posting
			},
			invalidHandler: function (form) {},
			errorPlacement: function (error, element) {
				if (element.is('select')) {
					error.insertAfter(element.siblings('.select2'));
				} else if (element.is(':radio')) {
					element.closest('.radio').addClass('form__label--error');
					error.insertAfter(element.closest('.radio'));
				} else if (element.is(':checkbox')) {
					element.closest('.checkbox').addClass('form__label--error');
					error.insertAfter(element.closest('.checkbox'));
				} else {
					error.insertAfter(element);
					if ($(element).hasClass('js-mask-phone')) {
						element.closest('.intl-tel-input').addClass('form__label--error');
					}
				}
			},
			success: function (element) {}
		});
	});
};

function checkValidtaionEl($el) {
	$el.each(function (key, el)	{
		let isValid = $el.valid();

		if (isValid) {
			if ($el.hasClass('js-radio'))	{
				$el
					.closest('.radio')
					.removeClass('form__label--error');
			} else if ($el.hasClass('js-checkbox'))	{
				$el
					.closest('.checkbox')
					.removeClass('form__label--error');
			}
			if ($el.hasClass('js-mask-phone')) {
				$el.closest('.intl-tel-input').removeClass('form__label--error');
			}
		} else {
			if ($el.hasClass('js-radio'))	{
				$el
					.closest('.radio')
					.addClass('form__label--error');
			} else if ($el.hasClass('js-checkbox'))	{
				$el
					.closest('.checkbox')
					.addClass('form__label--error');
			}
			if ($el.hasClass('js-mask-phone')) {
				$el.closest('.intl-tel-input').addClass('form__label--error');
			}
		}
	});
}

$(document).on('submit', '.js-form', function (event) {
	checkValidtaionEl($(this).find('.js-form__el'));
});

$(document).on('keyup click select2:select select2:unselect', '.js-form__el', function (e) {
	// console.log(e.type);
	checkValidtaionEl($(this));
});

initValidationForms();
